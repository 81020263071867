import { IcBilling, IcCOD, IcInfo, IcTransaction } from "components/SvgIcons";
import { TRANSACTION, PAYMENT_CHANNEL, COD, AUTO_VERIFY_SLIP } from "constants/Router";

export const TAB_CONFIGS = [
  {
    id: PAYMENT_CHANNEL,
    title: "deeplePay.header.paymentChannel",
    svgIcon: IcInfo,
  },
  // LEANBIZ_TEMP_DELETE hide transaction tab
  // {
  //   id: TRANSACTION,
  //   title: "deeplePay.header.transaction",
  //   svgIcon: IcTransaction,
  // },
  {
    id: COD,
    title: "deeplePay.header.cod",
    svgIcon: IcCOD,
  },
  {
    id: AUTO_VERIFY_SLIP,
    title: "autoVerification.header.verificationSlip",
    svgIcon: IcTransaction,
  },
  // LEANBIZ_TEMP_DELETE hide invoice tab
  // {
  //   id: DEEPLE_PAY_FEE_INVOICE,
  //   title: "deeplePay.header.invoice",
  //   svgIcon: IcBilling,
  // },
];
