import { SHIPPOP_CARRIER_NAME } from "constants/Shippop";
import { CARRIER_ICONS } from "constants/Image";
import { CARRIER_OPTIONS, CARRIERS, CALCULATE, CALCULATE_OPTIONS, SHIPPOP_CARRIER_OPTIONS } from "types/Shipping";

export const ALL_CARRIERS: string[] = [
  CARRIERS.BEST_EXPRESS,
  CARRIERS.CJ,
  CARRIERS.DHL,
  CARRIERS.FLASH_EXPRESS,
  CARRIERS.INTER_EXPRESS,
  CARRIERS.J_AND_T,
  CARRIERS.KERRY,
  CARRIERS.NIM_EXPRESS_CHILLED,
  CARRIERS.NIM_EXPRESS,
  CARRIERS.NINJAVAN,
  CARRIERS.PORLOR_EXPRESS,
  CARRIERS.SCG,
  CARRIERS.SENDIT,
  CARRIERS.THAI_POST_EMS,
  CARRIERS.THAI_POST_REGISTER,
  CARRIERS.TNT,
  CARRIERS.X_PRESSO,
  CARRIERS.ZTO,
  CARRIERS.THAI_POST_MYEXPRESS,
];

export const SHIPPING_CALCULATE_OPTIONS: CALCULATE_OPTIONS[] = [
  {
    value: CALCULATE.FIXED_PRICE,
    label: `shipping.label.calculateType.${CALCULATE.FIXED_PRICE}`,
  },
  {
    value: CALCULATE.DISTANCE_BASE_PRICE,
    label: `shipping.label.calculateType.${CALCULATE.DISTANCE_BASE_PRICE}`,
  },
  {
    value: CALCULATE.POSTAL_CODE_BASED_PRICE,
    label: `shipping.label.calculateType.${CALCULATE.POSTAL_CODE_BASED_PRICE}`,
  },
];

export const lalamoveLogoUrl = "https://storage.googleapis.com/leanbiz-core/static/carriers-logo/lalamove.svg";
export const shippopLogoUrl = "https://storage.googleapis.com/leanbiz-core/static/carriers-logo/shippop.png";

const carrierLogoUrl = "https://storage.googleapis.com/leanbiz-core/static/carriers-logo/";
// using react select custom component to display text with logo
export const CARRIER_OPTIONS_WITH_LOGO: CARRIER_OPTIONS[] = [
  {
    value: CARRIERS.THAI_POST_MYEXPRESS,
    label: CARRIERS.THAI_POST_MYEXPRESS,
    logo: `${carrierLogoUrl}thaipost_ems.png`,
  },
  {
    value: CARRIERS.BEST_EXPRESS,
    label: CARRIERS.BEST_EXPRESS,
    logo: `${carrierLogoUrl}best_express.png`,
  },
  {
    value: CARRIERS.CJ,
    label: CARRIERS.CJ,
    logo: `${carrierLogoUrl}cj_logistics.png`,
  },
  {
    value: CARRIERS.DHL,
    label: CARRIERS.DHL,
    logo: `${carrierLogoUrl}dhl.png`,
  },
  {
    value: CARRIERS.FLASH_EXPRESS,
    label: CARRIERS.FLASH_EXPRESS,
    logo: `${carrierLogoUrl}flash_express.png`,
  },
  {
    value: CARRIERS.INTER_EXPRESS,
    label: CARRIERS.INTER_EXPRESS,
    logo: `${carrierLogoUrl}inter_express.png`,
  },
  {
    value: CARRIERS.J_AND_T,
    label: CARRIERS.J_AND_T,
    logo: `${carrierLogoUrl}j_and_t.png`,
  },
  {
    value: CARRIERS.KERRY,
    label: CARRIERS.KERRY,
    logo: `${carrierLogoUrl}kerry.png`,
  },
  {
    value: CARRIERS.NIM_EXPRESS,
    label: CARRIERS.NIM_EXPRESS,
    logo: `${carrierLogoUrl}nim.png`,
  },
  {
    value: CARRIERS.NIM_EXPRESS_CHILLED,
    label: CARRIERS.NIM_EXPRESS_CHILLED,
    logo: `${carrierLogoUrl}nim.png`,
  },
  {
    value: CARRIERS.NINJAVAN,
    label: CARRIERS.NINJAVAN,
    logo: `${carrierLogoUrl}ninjavan.png`,
  },
  {
    value: CARRIERS.PORLOR_EXPRESS,
    label: CARRIERS.PORLOR_EXPRESS,
    logo: `${carrierLogoUrl}porlor.png`,
  },
  {
    value: CARRIERS.SCG,
    label: CARRIERS.SCG,
    logo: `${carrierLogoUrl}scg_express.png`,
  },
  {
    value: CARRIERS.SENDIT,
    label: CARRIERS.SENDIT,
    logo: `${carrierLogoUrl}sendit.png`,
  },
  {
    value: CARRIERS.THAI_POST_EMS,
    label: CARRIERS.THAI_POST_EMS,
    logo: `${carrierLogoUrl}thaipost_ems.png`,
  },
  {
    value: CARRIERS.THAI_POST_REGISTER,
    label: CARRIERS.THAI_POST_REGISTER,
    logo: `${carrierLogoUrl}thaipost.png`,
  },
  {
    value: CARRIERS.TNT,
    label: CARRIERS.TNT,
    logo: `${carrierLogoUrl}tnt.png`,
  },
  {
    value: CARRIERS.X_PRESSO,
    label: CARRIERS.X_PRESSO,
    logo: `${carrierLogoUrl}xpresso.png`,
  },
  {
    value: CARRIERS.ZTO,
    label: CARRIERS.ZTO,
    logo: `${carrierLogoUrl}zto.png`,
  },
  {
    value: CARRIERS.OTHER,
    label: CARRIERS.OTHER,
    logo: "",
  },
];

export const SHIPPOP_CARRIER_OPTIONS_WITH_LOGO: SHIPPOP_CARRIER_OPTIONS[] = Object.values(SHIPPOP_CARRIER_NAME).map(
  (carrierName) => {
    let carrierNameForLogo: string = carrierName;

    if (carrierName.includes(CARRIERS.KERRY)) {
      carrierNameForLogo = CARRIERS.KERRY;
    } else if (carrierName.includes("JWD express")) {
      carrierNameForLogo = "JWD express";
    }

    const carrierIconUrl = CARRIER_ICONS[carrierNameForLogo] || CARRIER_ICONS.DEFAULT_CARRIER;

    return {
      value: carrierName,
      label: carrierName,
      logo: carrierIconUrl,
    };
  },
);

export const DISTANCE_BASE_PRICE = "คำนวณค่าขนส่งตามระยะทาง";
export const POSTAL_CODE_BASED_PRICE = "ราคาค่าจัดส่งตามรหัสไปรษณีย์";
export const CALCULATE_FROM_WEIGHT_AND_SIZE = "คิดจากน้ำหนักและขนาดของพัสดุ";
export const DISTANCE_BASED = "สอบถามค่าส่งจากแอดมิน";

export const CASH_ON_DELIVERY = "เก็บปลายทาง";
export const CASH_ON_DELIVERY_DEPOSIT = "มีค่ามัดจำ";
export const CASH_ON_DELIVERY_DEPOSIT_THRESHOLD = "ยอดสั่งซื้อขั้นต่ำ";

export const MAPPED_SHIPPOP_ERROR: Record<string, string> = {
  "SHIPPOP_ESTIMATED_SHIPPING:PRICE_ORDER_NOT_FOUND": "SHIPPOP_ESTIMATED_SHIPPING_PRICE_ORDER_NOT_FOUND",
  "SHIPPOP_ESTIMATED_SHIPPING_PRICE:ORDER_IS_NOT_SHIPPOP": "SHIPPOP_ESTIMATED_SHIPPING_PRICE_ORDER_IS_NOT_SHIPPOP",
  "SHIPPOP_ESTIMATED_SHIPPING:PRICE:NOT_SUPPORTED_CARRIER_NAME":
    "SHIPPOP_ESTIMATED_SHIPPING_PRICE_NOT_SUPPORTED_CARRIER_NAME",
  "SHIPPOP_ESTIMATED_SHIPPING_PRICE:FAIL_TO_QUOTE_SHIPPOP_SHIPPING_PRICE":
    "SHIPPOP_ESTIMATED_SHIPPING_PRICE_FAIL_TO_QUOTE_SHIPPOP_SHIPPING_PRICE",
};

export const LALAMOVE_URL = "https://www.lalamove.com/en-th/all-vehicle-pricing-detail";
export const SHIPPOP_API_KEY_DOC = "https://www1.shippop.com/embed/register/deeple/";
