import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "components/Typography";
import Grid from "components/Grid";
import COLORS from "constants/Colors";

type ThaipostNoteProps = {
  className?: string;
};

const ThaipostNote: React.FC<ThaipostNoteProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} className={className || "mt-3"}>
      <Typography variant="body1" color={COLORS.DarkMed} className="flex items-center">
        <span className="mr-2">•</span>
        {t("This carrier will use Thaipost API and automatically get tracking number and be able to print parcel")}
      </Typography>
      <Typography variant="body1" color={COLORS.DarkMed} className="mt-2 flex items-center">
        <span className="mr-2">•</span>
        {t("Tracking number will send automatically to customers when store delivered package to Thaipost")}
      </Typography>
      <Typography variant="body1" color={COLORS.DarkMed} className="mt-2 flex items-center">
        <span className="mr-2">•</span>
        {t("Quote Shipping price will be deducted from shipping credit and settle when get the actual price from Thaipost")}
      </Typography>
    </Grid>
  );
};

export default ThaipostNote; 