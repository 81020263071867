import { OptionType } from "react-select";
import { ShippingMethod as METHOD } from "@leanbizai/shopping-cart-calculator";

import { BranchType } from "types/Branch";
import { CustomerAddressType } from "types/Customer";
import { CODPaymentType } from "types/Payment";
import { COD_DEPOSIT_TYPES } from "constants/Payment";

export { ShippingMethod as METHOD } from "@leanbizai/shopping-cart-calculator";

export enum ShipmentType {
  FIXED_PRICE = "FIXED_PRICE",
  POSTAL_CODE = "POSTAL_CODE",
  MANUAL = "MANUAL",
  SHIPPOP = "SHIPPOP",
  LALAMOVE = "LALAMOVE",
  PICKUP = "PICKUP",
}

export enum CARRIERS {
  BEST_EXPRESS = "BEST express",
  CJ = "CJ logistics",
  DHL = "DHL",
  FLASH_EXPRESS = "Flash express",
  INTER_EXPRESS = "Inter express",
  J_AND_T = "J&T",
  KERRY = "Kerry",
  NIM_EXPRESS = "NIM express",
  NIM_EXPRESS_CHILLED = "NIM express chilled",
  NINJAVAN = "Ninjavan",
  OTHER = "Other",
  PORLOR_EXPRESS = "Porlor express",
  SCG = "SCG yamato express",
  SENDIT = "Sendit",
  THAI_POST_EMS = "Thaipost-EMS",
  THAI_POST_REGISTER = "Thaipost-Register",
  TNT = "TNT",
  X_PRESSO = "Xpresso",
  ZTO = "ZTO",
  THAI_POST_MYEXPRESS = "Thaipost-MyExpress",
}

export enum SHIPPOP_CALCULATE {
  FLAT_RATE_PRICE = "FLAT_RATE_PRICE",
  POSTAL_CODE_BASED_PRICE = "POSTAL_CODE_BASED_PRICE",
  SHIPPOP_BASE_PRICE = "SHIPPOP_BASE_PRICE",
}

export enum CALCULATE {
  UNIT_PRICE = "UNIT_PRICE",
  FIXED_PRICE = "FIXED_PRICE",
  DISTANCE_BASE_PRICE = "DISTANCE_BASE_PRICE",
  LALAMOVE = "LALAMOVE",
  MANUAL = "MANUAL",
  SHIPPOP = "SHIPPOP",
  POSTAL_CODE_BASED_PRICE = "POSTAL_CODE_BASED_PRICE",
}

export enum METHOD_TYPE {
  DELIVERY = "DELIVERY",
  /**
   * @deprecated
   */
  PICK_UP = "PICK_UP",
  PICKUP = "PICKUP",
  STANDARD = "STANDARD",
  EXPRESS = "EXPRESS",
  DIGITAL = "DIGITAL",
}

export enum SHIPPOP_INVOICE_SIZE {
  A4 = "A4",
  A5 = "A5",
  A6 = "A6",
  RECEIPT = "receipt",
  LETTER = "letter",
  LETTER4x6 = "letter4x6",
  STICKER = "sticker",
  STICKER4x6 = "sticker4x6",
  PAPERANG = "paperang",
}

export type CALCULATE_OPTIONS = {
  value: CALCULATE;
} & OptionType;

export type CarrierOption = {
  logo: string;
} & OptionType;

export type ProvinceSetting = {
  name: string;
  isActive: boolean;
  price?: number;
  postalCodes?: PostalCodeSetting[];
};

export type PostalCodeSetting = {
  postalCode: string;
  price: number;
};

export type ShippopSetting = {
  type: SHIPPOP_CALCULATE;
  managementFee: number;
};

export type ShippingMethodSetting = {
  shippopSetting?: ShippopSetting;
  provinceSettings: ProvinceSetting[];
};

export type ShippingMethod = {
  methodType: METHOD_TYPE;
  method: METHOD;
  calculateType: CALCULATE;
  pricePerUnit: number;
  minimumPrice: number;
  maximumPrice: number;
  fixedPrice: number;
  minDuration: number;
  maxDuration: number;
  description: string;
  disabled: boolean;
  disabledReason?: string;
  totalShippingPrice?: number;
  settings?: ProvinceSetting[]; // Deprecate soon
  setting?: ShippingMethodSetting | null;
  disabledDuration: boolean;
  isCODPayment?: boolean;
  isReceiveOrderOutsideDeliveryTime?: boolean;
  isDisabledSchedulePickUp: boolean;
  isAllowedOnlyCOD: boolean;
};

export type ShippingMethodWithPriceQueryType = {
  data: { shippingMethodWithPrice: ShippingMethodWithPriceType[] };
};

export type ShippingMethodWithPriceType = ShippingMethod & {
  maximumPrice: number;
  minimumPrice: number;
  pricePerUnit: number;
  totalShippingPrice: number;
  shippingPrice: number;
  isCODPayment?: boolean;
  offlineStore?: BranchType;
  CODPayment?: CODPaymentType;
};

export type CARRIER_OPTIONS = {
  logo: string;
  value: CARRIERS | "";
} & OptionType;

export type SHIPPOP_CARRIER_OPTIONS = {
  logo: string;
} & OptionType;

export type CustomShippingMethod = {
  id: string;
  method: METHOD;
  calculateType: CALCULATE;
  fixedPrice: number;
  minDuration: number;
  maxDuration: number;
  pricePerUnit: number;
  minimumPrice: number;
  description: string;
  productSKUs: {
    id: string;
    product: {
      name: string;
    };
  };
};

export type CarrierType = { label: string; value: string; logo: string };

export type ShippingListType = {
  id?: string;
  carrierName: CarrierType;
  trackingNumber: string;
  otherCarrierName?: string;
  deliveryDate?: string; // TODO: remove require deliveryDate on backend
  note?: string;
};

export type TrackingShippedListType = {
  carrierName: string;
  trackingNumber: string;
  otherCarrierName?: string;
  deliveryDate?: string;
  note?: string;
};

type ShippingOrderInputDetailType = {
  trackingNumber: string;
  note?: string;
};

export type ShippingOrderInputType = {
  detail: ShippingOrderInputDetailType[];
};

export type ShippopStoreSetting = {
  email?: string;
  senderInformation?: ShippopSenderInformation[] | null;
  credential?: ShippopCredential;
} | null;

export type DefaultShippingMethodType = {
  shipping: ShippingMethod[];
  image: string;
  shippop: ShippopStoreSetting;
};

export type DefaultShippingMethodQueryType = {
  defaultShippingMethod: DefaultShippingMethodType;
};

export type FormStateType = {
  shippingMethod: METHOD;
  shippingMethodType: METHOD_TYPE;
  shippingAddress: CustomerAddressType;
  selectedBranchId: string;
  shippingPrice: number;
  deliverySchedule: string;
};

export type DeliveryMethodFormDataType = {
  offlineStoreId?: string;
  shippingMethod?: METHOD;
  isCODPayment?: boolean;
  CODPayment?: CODPaymentType;
  shippingPrice: number;
  isSentResponseMessage?: boolean;
  customerName?: string;
  phoneNumber?: string;
  deliverySchedule?: string;
  shippingId?: string;
};

export type BranchSelectionFormDataType = {
  customerId?: string;
  customerName?: string;
  phoneNumber: string;
  branchId: string;
  scheduledTime: string;
  isSentResponseMessage?: boolean;
  shippingAddress?: CustomerAddressType;
  email: string;
  firstName: string;
  lastName: string;
  note?: string;
};

export type ShippingType = {
  calculateType?: CALCULATE;
  carrier: string;
  method: METHOD;
  rate: number;
  duration: number;
  isCODPayment?: boolean;
  CODPayment?: CODPaymentType;
};

export type DistrictType = {
  dTH: string; // districtTH เขต อำเภอ
  dEN: string; // districtEN
  pC: string; // postalCode
};

export type ProvinceType = {
  name: string;
  provinceTH: string;
  provinceEN: string;
  districts: DistrictType[];
};

export type PostalCodesWithCustomPriceType = { price: number; postalCode: string };

export type SettingsPostalCodeType = {
  name: string;
  price?: number;
  isActive: boolean;
  postalCodes?: PostalCodesWithCustomPriceType[];
};

export type ShippopSenderInformation = {
  name: string;
  address: string;
  phoneNumber: string;
  district: string;
  subDistrict: string;
  province: string;
  postalCode: string;
  isActive: boolean;
};

export enum SHIPPOP_ERROR_CODE {
  DAY_OFF = "DAY_OFF",
  ERR_COD_AMOUNT_EXCEED = "ERR_COD_AMOUNT_EXCEED",
  ERR_DEST = "ERR_DEST",
  ERR_LAT_LNG = "ERR_LAT_LNG",
  ERR_MAINTENANCE = "ERR_MAINTENANCE",
  ERR_NOT_SUPPORT_COD = "ERR_NOT_SUPPORT_COD",
  ERR_ORIGIN = "ERR_ORIGIN",
  ERR_OUT_OF_AREA = "ERR_OUT_OF_AREA",
  ERR_OVER_SIZE = "ERR_OVER_SIZE",
  ERR_OVER_WEIGHT = "ERR_OVER_WEIGHT",
  ERR_POSTCODE = "ERR_POSTCODE",
  ERR_REALTIME_CHECKPRICE = "ERR_REALTIME_CHECKPRICE",
  ERR_REVERSE_GEOCODE_FAILURE = "ERR_REVERSE_GEOCODE_FAILURE",
  ERR_SIZE = "ERR_SIZE",
  NOT_SUPPORT_COD = "NOT_SUPPORT_COD",
  SERVICE_MAINTENANCE = "SERVICE_MAINTENANCE",
  INVALID_SIZE = "INVALID_SIZE",
  INVALID_WEIGHT = "INVALID_WEIGHT",
  "SHIPPOP_PURCHASE_ORDER_SUMMARY:INVALID_SIZE" = "SHIPPOP_PURCHASE_ORDER_SUMMARY:INVALID_SIZE",
  "SHIPPOP_PURCHASE_ORDER_SUMMARY:INVALID_WEIGHT" = "SHIPPOP_PURCHASE_ORDER_SUMMARY:INVALID_WEIGHT",
}

export type ShippopPurchaseOrderSummaryError = {
  carrier: string;
  errorCode: SHIPPOP_ERROR_CODE;
  orderId: number;
  remark: string;
  orderNumber: string;
};

export type LalamoveDriverInfo = {
  name: string;
  phone: string;
  plateNumber: string;
  photo: string;
};

export type ShipmentDetail = {
  id: string;
  projectId: string;
  orderId: string;
  referenceId: string;
  type: string;
  status: string;
  driver: LalamoveDriverInfo;
  assigningDriverAt: string;
  onGoingAt: string;
  cancelledAt: string;
  pickedUpAt: string;
  rejectedAt: string;
  completedAt: string;
  expiredAt: string;
  createdAt: string;
  updatedAt: string;
};

export type CarrierOptionType = SHIPPOP_CARRIER_OPTIONS | CARRIER_OPTIONS;

export interface BasePriceConfiguration {
  fee: number;
  isAppliedPromotion?: boolean;
  type: COD_DEPOSIT_TYPES;
}

export interface NonePriceConfiguration extends BasePriceConfiguration {
  type: COD_DEPOSIT_TYPES.NONE;
}

export interface FixedPricePriceConfiguration extends BasePriceConfiguration {
  type: COD_DEPOSIT_TYPES.FIXED_PRICE;
}

export interface PercentagePriceConfiguration extends BasePriceConfiguration {
  type: COD_DEPOSIT_TYPES.PERCENTAGE;
  percent: number;
}

export type PriceConfiguration = NonePriceConfiguration | FixedPricePriceConfiguration | PercentagePriceConfiguration;

export interface Credential {
  apiKey: string;
}

export interface LalamoveCredential extends Credential {
  apiSecret: string;
}

export type ShippopCredential = Credential;

export type ShippingQuotationWithPriceQueryType = {
  data: { shippingQuotations: ShippingQuotationType[] };
};

export interface ShippingDuration {
  max: number;
  min: number;
  isActive: boolean;
}

export interface CodInformation {
  minTotalPriceOrder: number;
  managementFee: PriceConfiguration;
  deposit: PriceConfiguration;
  threshold: PriceConfiguration;
  paymentType: PaymentType; // cash, credit and all
}

export enum PaymentType {
  ALL = "ALL",
  CASH = "CASH",
  CREDIT = "CREDIT",
}

export enum ShippingDeliveryType {
  FIXED_PRICE = "FIXED_PRICE",
  POSTAL_CODE = "POSTAL_CODE",
  MANUAL = "MANUAL",
  SHIPPOP = "SHIPPOP",
  LALAMOVE = "LALAMOVE",
  PICKUP = "PICKUP",
}

export interface Shipping {
  id: string;
  projectId: string;
  type: ShippingDeliveryType;
  duration: ShippingDuration;
  name: string;
  description: string;
  codInformation: CodInformation;
  isActive: boolean;
  isCod: boolean;
  isDeleted: boolean;
  managementFee: PriceConfiguration;
  setting: ShippingSetting;

  // #region System courier information
  code: string;
  enDisplayName: string;
  iconUrl?: string;
  thDisplayName: string;
  trackingUrl?: string;
  // #endregion

  // #region Disable information
  isDisabled: boolean;
  disabledReason: string;
  // #endregion
}

interface FixedPriceShipping {
  price: number;
}

export interface PostalCodeShipping {
  price: number;
  provinces: Provinces;
}

type ManualShipping = {};

type PickUpShipping = {};

export interface LalamoveShipping {
  credential: LalamoveCredential;
  isReceivedOrderOutsideDeliveryTime: boolean;
  price?: number; // Price from lalamove for lower price of shipping
}

export interface ShippopShipping {
  readonly credential: ShippopCredential;
  readonly price: number;
  readonly senderInformation: ReadonlyArray<SenderInformation>;
  readonly provinces: Provinces;
  readonly provider: string; // NINJAVAN
  readonly type: string; // SHIPPOP_BASE_PRICE, FLAT_RATE_PRICE, POSTAL_CODE_BASED_PRICE
  readonly isDisabledSchedulePickUp: boolean;
  readonly isReceivedOrderOutsideDeliveryTime: boolean;
}

export type ShippingSetting =
  | FixedPriceShipping
  | PostalCodeShipping
  | ManualShipping
  | LalamoveShipping
  | ShippopShipping
  | PickUpShipping;

export interface SenderInformation {
  readonly address: string;
  readonly district: string;
  readonly province: string;
  readonly postalCode: number;
  readonly phoneNumber: string;
  readonly subDistrict: string;
  readonly name: string;
  readonly isActive: boolean;
}

export interface Provinces {
  readonly [provinceKey: string]: {
    readonly price: number;
    readonly isActive: boolean;
    readonly postalCodes: PostalCode;
  };
}

export interface PostalCode {
  readonly [postalCodeKey: number]: {
    readonly isActive: boolean;
    readonly price: number;
  };
}

export type ShippingDurationType = {
  readonly min: number;
  readonly max: number;
  readonly isActive: boolean;
};

export type ShippingCodInformationType = {
  readonly threshold: PriceConfigurationType;
  readonly deposit: PriceConfigurationType;
  readonly managementFee: PriceConfigurationType;
  readonly paymentType: string;
  readonly minTotalPriceOrder: number;
};

/**
 * Price configuration type but we are calling it
 * 'PriceConfigurationTypeType' as we are still adopting
 * 'Type' suffix
 */
export enum PriceConfigurationTypeType {
  NONE = "NONE",
  PERCENTAGE = "PERCENTAGE",
  FIXED_PRICE = "FIXED_PRICE",
}

export type PriceConfigurationType = {
  readonly type: PriceConfigurationTypeType;
  readonly fee: number;
  readonly percent: number;
};

export type ShippingQuotationType = {
  readonly enDisplayName: string;
  readonly iconUrl?: string;
  readonly name: string;
  readonly price: number;
  readonly totalShippingPrice: number;
  readonly originalPriceWithManagementFee: number;
  readonly isDisabled: boolean;
  readonly isCod: boolean;
  readonly disabledReason?: string;
  readonly shippingId: string;
  readonly thDisplayName: string;
  /**
   * Shipping type; this should not be type as string but
   * it should be shipping type but since we are transition between
   * old and new one. Creating new one at this point might not be best
   */
  readonly type: string;
  readonly duration: ShippingDurationType;
  readonly codInformation: ShippingCodInformationType;
  readonly managementFee: PriceConfigurationType;
  readonly trackingUrl?: string;
  readonly offlineStore?: BranchType;
  readonly credential?: ShippopCredential | LalamoveCredential;
};
